<template>
  <!-- <BaseView> -->
  <CampanhasEtapas></CampanhasEtapas>
  <!-- </div>
 -->
</template>

<script>
// import BaseView from "@/template/BaseView.vue";
import CampanhasEtapas from "@/components/Campanhas/CampanhasEtapas.vue";

export default {
  components: {
    // BaseView,
    CampanhasEtapas,
  },
  // created() {
  //   if (this.$store.getters.user.user.level == 'blogger') {this.$router.push("/dynamicRoute/AccessDenied")};
  // },
};
</script>

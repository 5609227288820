<template>
  <b-sidebar
    id="sidebar-ActionStepsCampanha"
    right
    backdrop
    shadow
    ref="sidebar-ActionStepsCampanha"
  >
    <div v-if="cardSelected">
      <div class="container-title">
        <h1 class="title">{{ filterTitle(cardSelected.reference_id) }}</h1>
        <img
          @click="openModal('CampanhaModalEmail')"
          style="cursor: pointer; right: 35px; position: relative"
          src="@/assets/img/icons/campanhas/edit-email-modal.svg"
          alt="email"
        />
      </div>
      <div class="container-side">
        <label>O que será feito</label>
        <div class="flex">
          <img
            src="@/assets/img/icons/campanhas/detail-email.svg"
            alt="email"
          />
          <p>Enviar e-mail</p>
        </div>
        <div v-if="cardSelected.send_at && cardSelected.send_at != 'null|null'">
          <label>Esperar para executar a ação</label>
          <div class="flex">
            <img
              src="@/assets/img/icons/campanhas/detail-clock.svg"
              alt="tempo"
            />
            <p>{{ filterTimeCard(cardSelected.send_at) }}</p>
          </div>
        </div>
        <label>Remetente</label>
        <div class="flex" v-if="cardSelected.reference_id">
          <img
            src="@/assets/img/icons/campanhas/detail-sender.svg"
            alt="division"
          />
          <p
            v-if="
              cardSelected &&
              cardSelected.reference_id &&
              cardSelected.reference_id != 0
            "
          >
            {{ filterRemetente(cardSelected.reference_id) }}
          </p>
        </div>
        <label>Destinatários</label>
        <div class="flex">
          <img
            src="@/assets/img/icons/campanhas/detail-peoples.svg"
            alt="division"
          />
          <div style="display: grid">
            <p>{{ filterSendType(cardSelected.send_to) }}</p>
            <p
              class="container-tag_specific"
              v-if="
                cardSelected.send_to === 'tag_specific' &&
                cardSelected.send_filter &&
                cardSelected.send_filter.tag_dispatch.name
              "
            >
              Tag :
              <span>'{{ cardSelected.send_filter.tag_dispatch.name }}'</span>
            </p>
          </div>
        </div>
        <div
          v-if="
            cardSelected &&
            cardSelected.send_filter &&
            cardSelected.send_filter.email_tag_open &&
            cardSelected.send_filter.email_tag_click
          "
        >
          <label>Funil de e-mail</label>
          <div style="display: grid; gap: 15px">
            <div
              class="flex"
              v-if="cardSelected.send_filter.email_tag_open.tag.id != null"
            >
              <img
                src="@/assets/img/icons/campanhas/sidebar-automation-open.svg"
                alt="division"
              />
              <div>
                <p>Quando abrir:</p>
                <p class="text-automation">
                  Add a tag
                  <span
                    >‘{{
                      cardSelected.send_filter.email_tag_open.tag.name
                    }}’</span
                  >
                  e a pontuação de
                  <span>{{
                    cardSelected.send_filter.email_tag_open.points
                  }}</span>
                </p>
              </div>
            </div>
            <div
              class="flex"
              v-if="cardSelected.send_filter.email_tag_click.tag.id != null"
            >
              <img
                src="@/assets/img/icons/campanhas/sidebar-automation-click.svg"
                alt="division"
              />
              <div>
                <p>Quando clicar:</p>
                <p class="text-automation2">
                  Add a tag
                  <span
                    >‘{{
                      cardSelected.send_filter.email_tag_click.tag.name
                    }}’</span
                  >
                  e a pontuação de
                  <span>{{
                    cardSelected.send_filter.email_tag_click.points
                  }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <label>Dados da entrega</label>
        <div class="flex" v-if="cardSelected.reference_id">
          <div style="display: grid; gap: 15px">
            <div class="flex">
              <img
                src="@/assets/img/icons/campanhas/open-email.svg"
                alt="division"
              />
              <p>0 e-mails abertos</p>
            </div>
            <div class="flex">
              <img
                src="@/assets/img/icons/campanhas/click-email.svg"
                alt="division"
              />
              <p>0 cliques nos e-mails</p>
            </div>
          </div>
        </div>
        <BaseButton class="mt-3" variant="primary" @click="visualizaEventos">
          Visualizar todos os eventos
        </BaseButton>
        <label v-if="cardSelected.parent != 0">Remover item</label>
        <div
          v-if="cardSelected.parent != 0 && show_remove_card"
          class="flex"
          v-b-toggle.sidebar-ActionStepsCampanha
          @click="$parent.removeCard(cardSelected)"
        >
          <img src="@/assets/img/icons/campanhas/trash.svg" alt="division" />
          <p class="delete">Clique para excluir esse item do fluxo</p>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center" v-else>
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </b-sidebar>
</template>

<script>
import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();
export default {
  props: ["cardSelected", "emails"],
  data() {
    return {
      loading: false,
      remetente: [],
    };
  },
  methods: {
    show_remove_card() {
      var variable_return;
      var proximoCard = [];
      if (this.cardSelected.parent != 0) {
        return true;
      } else {
        for (let i = 0; i < this.cards.length; i++) {
          if (this.cards[i].parent == this.cardSelected.id) {
            proximoCard.push(this.cards[i]);
          }
        }
        if (proximoCard.length) {
          variable_return = false;
        } else {
          variable_return = true;
        }
        return variable_return;
      }
    },
    visualizaEventos() {
      this.$router.push({
        name: "Eventos do e-mail",
        query: {
          email: this.cardSelected.reference_id,
        },
      });
    },
    filterSendType(type) {
      if (!type) {
        return "Enviar para todos os leads";
      }
      const all_types = {
        all_leads: "Enviar para todos os leads",
        open_not_click: "Abriram, mas não clicaram",
        not_open: "Nem abriram o e-mail",
        click_objective: "Clicaram no objetivo",
        tag_specific: "Enviar para Tag especifica",
      };
      return all_types[type];
    },
    openModal(data) {
      this.$parent.openModal(data, this.cardSelected, "edit_email");
    },
    filterRemetente(id) {
      if (id && id != 0) {
        const emails = this.emails.find((email) => email.id == id);
        const remetente = this.remetente.data.find(
          (remetente) => remetente.id == emails.from_id
        );
        return remetente ? remetente.email : "";
      }
    },
    filterTimeCard(time) {
      if (
        time === null ||
        time === "0" ||
        time === "" ||
        time === "null|null"
      ) {
        return "Envio não definido";
      }

      const [days, timeString] = time.split("|");
      const [hours, minutes] = timeString.split(":");

      let formattedTime = "";

      if (days !== "0") {
        formattedTime += `${days} ${days !== "1" ? "dias" : "dia"}`;
      }

      if (hours !== "00") {
        formattedTime += ` ${hours} ${hours !== "01" ? "horas" : "hora"}`;
      }

      if (minutes !== "00") {
        formattedTime += ` ${minutes} ${
          minutes !== "01" ? "minutos" : "minuto"
        }`;
      }

      return formattedTime.trim();
    },
    filterTitle(id) {
      const emails = this.emails.find((email) => email.id == id);
      return `Detalhes do ${emails ? emails.title : "Item"}`;
    },
    getRemetente() {
      serviceSettings
        .read("/sender")
        .then((resp) => {
          this.remetente = resp;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
  },
  mounted() {
    this.getRemetente();
  },
};
</script>

<style scoped lang="scss">
.container-tag_specific {
  font-size: 14px;
  color: #9a9a9a;
  span {
    text-decoration: underline;
    color: #00a650;
  }
}
.text-automation {
  font-size: 14px;
  color: #9a9a9a;
  span {
    color: #00a650;
  }
}
.text-automation2 {
  font-size: 14px;
  color: #9a9a9a;
  span {
    color: #3483fa;
  }
}
.container-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;

  color: #9a9a9a;
  margin-top: 35px;
  margin-bottom: 20px;
}
.title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.flex {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}
p {
  margin: 0px !important;
}
.container-side {
  .delete {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-decoration-line: underline;

    color: #f10d43;
  }
}
</style>

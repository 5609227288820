var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseModal',{attrs:{"name":"CampanhaModalQualificacao","id":"CampanhaModalQualificacao","idModal":"CampanhaModalQualificacao","size":"md","title":"Qualificação e Automações"},on:{"shown":_vm.getTagsCard,"hidden":_vm.clean},scopedSlots:_vm._u([{key:"footer",fn:function({ cancel }){return [_c('BaseButton',{staticClass:"mr-4",attrs:{"variant":"link-info"},on:{"click":cancel}},[_vm._v(" Cancelar ")]),_c('BaseButton',{attrs:{"variant":"black","disabled":_vm.loading},on:{"click":_vm.saveTags}},[_vm._v(" Salvar Alterações ")])]}}])},[_c('div',{staticClass:"container-qualification"},[_c('div',[_c('div',{staticClass:"content-qualification"},[_c('div',{staticClass:"content-tags"},[_c('label',{attrs:{"for":"selected_tag_open"}},[_vm._v("Tag ao abrir o e-mail")]),_c('BaseSelect',{attrs:{"id":"selected_tag_open","selectModel":_vm.selected_tag_open,"placeholder":"Selecione uma Tag","track-by":"id","trackname":"name","specificType":"tags","watch":true,"array":_vm.tags,"multiple":false,"selectLabel":"","deselectLabel":"","selectedLabel":"","loading":_vm.loadingTags,"noResult":"Nenhuma tag encontrada."},on:{"search":function($event){return _vm.debounceTags($event)},"callback":function($event){return _vm.fetchTags($event)},"change":function($event){_vm.selected_tag_open = $event}}})],1),_c('div',{staticClass:"content-tags"},[_c('label',{attrs:{"for":"points_open_email"}},[_vm._v("Pontos por abrir o e-mail")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.points_open_email),expression:"points_open_email"}],staticStyle:{"width":"100%"},attrs:{"id":"points_open_email","type":"number","min":"0","max":"300"},domProps:{"value":(_vm.points_open_email)},on:{"input":function($event){if($event.target.composing)return;_vm.points_open_email=$event.target.value}}}),_c('span',{staticStyle:{"position":"absolute","bottom":"27px","left":"60px"}},[_vm._v("pontos ")])])]),(
          _vm.selected_tag_open &&
          _vm.selected_tag_open.name &&
          _vm.selected_tag_open.name != 'Selecione uma Tag'
        )?_c('div',{staticClass:"content-text-tags"},[_c('p',[_vm._v(" Ao abrir o e-mail, o lead receberá a tag "),_c('span',[_vm._v("‘"+_vm._s(_vm.selected_tag_open.name)+"’")]),_vm._v(" e "),_c('span',[_vm._v(_vm._s(_vm.points_open_email)+" pontos")]),_vm._v(" no seu perfil ")])]):_vm._e()]),_c('div',[_c('div',{staticClass:"content-qualification"},[_c('div',{staticClass:"content-tags blue"},[_c('label',{attrs:{"for":"selected_tag_click"}},[_vm._v("Tag ao clicar no e-mail")]),_c('BaseSelect',{attrs:{"id":"selected_tag_click","selectModel":_vm.selected_tag_click,"placeholder":"Selecione uma Tag","track-by":"id","trackname":"name","specificType":"tags","array":_vm.tags,"watch":true,"multiple":false,"selectLabel":"","deselectLabel":"","selectedLabel":"","loading":_vm.loadingTags,"noResult":"Nenhuma tag encontrada."},on:{"search":function($event){return _vm.debounceTags($event)},"callback":function($event){return _vm.fetchTags($event)},"change":function($event){_vm.selected_tag_click = $event}}})],1),_c('div',{staticClass:"content-tags"},[_c('label',{attrs:{"for":"points_click_email"}},[_vm._v("Pontos por clicar no e-mail")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.points_click_email),expression:"points_click_email"}],staticStyle:{"width":"100%"},attrs:{"id":"points_click_email","type":"number","min":"0","max":"300"},domProps:{"value":(_vm.points_click_email)},on:{"input":function($event){if($event.target.composing)return;_vm.points_click_email=$event.target.value}}}),_c('span',{staticStyle:{"position":"absolute","bottom":"27px","left":"60px"}},[_vm._v("pontos ")])])]),(
          _vm.selected_tag_click &&
          _vm.selected_tag_click.name &&
          _vm.selected_tag_click.name != 'Selecione uma Tag'
        )?_c('div',{staticClass:"content-text-tags2"},[_c('p',[_vm._v(" Ao clicar no e-mail, o lead receberá a tag "),_c('span',[_vm._v("‘"+_vm._s(_vm.selected_tag_click.name)+"’")]),_vm._v(" e "),_c('span',[_vm._v(_vm._s(_vm.points_click_email)+" pontos")]),_vm._v(" no seu perfil ")])]):_vm._e()])]),(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
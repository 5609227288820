<template>
  <BaseModal
    name="CampanhaModalQualificacao"
    id="CampanhaModalQualificacao"
    idModal="CampanhaModalQualificacao"
    size="md"
    title="Qualificação e Automações"
    @shown="getTagsCard"
    @hidden="clean"
  >
    <div class="container-qualification">
      <!-- Tag ao abrir -->
      <div>
        <div class="content-qualification">
          <div class="content-tags">
            <label for="selected_tag_open">Tag ao abrir o e-mail</label>
            <BaseSelect
              id="selected_tag_open"
              :selectModel="selected_tag_open"
              placeholder="Selecione uma Tag"
              track-by="id"
              trackname="name"
              specificType="tags"
              :watch="true"
              :array="tags"
              :multiple="false"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              @search="debounceTags($event)"
              :loading="loadingTags"
              noResult="Nenhuma tag encontrada."
              @callback="fetchTags($event)"
              @change="selected_tag_open = $event"
            >
            </BaseSelect>
          </div>
          <div class="content-tags">
            <label for="points_open_email">Pontos por abrir o e-mail</label>
            <input
              style="width: 100%"
              id="points_open_email"
              type="number"
              min="0"
              max="300"
              v-model="points_open_email"
            />
            <span style="position: absolute; bottom: 27px; left: 60px"
              >pontos
            </span>
          </div>
        </div>
        <div
          class="content-text-tags"
          v-if="
            selected_tag_open &&
            selected_tag_open.name &&
            selected_tag_open.name != 'Selecione uma Tag'
          "
        >
          <p>
            Ao abrir o e-mail, o lead receberá a tag
            <span>‘{{ selected_tag_open.name }}’</span> e
            <span>{{ points_open_email }} pontos</span> no seu perfil
          </p>
        </div>
      </div>
      <!--Tag ao clicar  -->
      <div>
        <div class="content-qualification">
          <div class="content-tags blue">
            <label for="selected_tag_click">Tag ao clicar no e-mail</label>

            <BaseSelect
              id="selected_tag_click"
              :selectModel="selected_tag_click"
              placeholder="Selecione uma Tag"
              track-by="id"
              trackname="name"
              specificType="tags"
              :array="tags"
              :watch="true"
              :multiple="false"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              @search="debounceTags($event)"
              :loading="loadingTags"
              noResult="Nenhuma tag encontrada."
              @callback="fetchTags($event)"
              @change="selected_tag_click = $event"
            >
            </BaseSelect>
          </div>
          <div class="content-tags">
            <label for="points_click_email">Pontos por clicar no e-mail</label>
            <input
              style="width: 100%"
              id="points_click_email"
              type="number"
              min="0"
              max="300"
              v-model="points_click_email"
            />
            <span style="position: absolute; bottom: 27px; left: 60px"
              >pontos
            </span>
          </div>
        </div>
        <div
          class="content-text-tags2"
          v-if="
            selected_tag_click &&
            selected_tag_click.name &&
            selected_tag_click.name != 'Selecione uma Tag'
          "
        >
          <p>
            Ao clicar no e-mail, o lead receberá a tag
            <span>‘{{ selected_tag_click.name }}’</span> e
            <span>{{ points_click_email }} pontos</span> no seu perfil
          </p>
        </div>
      </div>
    </div>
    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="black" :disabled="loading" @click="saveTags">
        Salvar Alterações
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>

<script>
import Multiselect from "vue-multiselect";
//
import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();
//
import FunnelService from "@/services/resources/FunnelService";
const serviceFunnel = FunnelService.build();
//
import TagService from "@/services/resources/TagService";
const serviceListarTag = TagService.build();
//
import _ from "lodash";

export default {
  props: ["cardSelected", "type_api_email","qualification","automations "],
  components: {
    Multiselect,
  },
  data() {
    return {
      loadingTags: false,
      searchTags: "",
      pageTags: 1,

      points_click_email: 0,
      points_open_email: 0,
      tags: [],
      selected_tag_open: "",
      selected_tag_click: "",
      loading: false,
      stop: false,
      stopTags: false,
    };
  },
  watch: {
    points_open_email() {
      if (this.points_open_email < 0) {
        this.points_open_email = 0;
        this.$grToast.toast("Limite de pontos mínimos atingidos!", {
          title: "Atenção",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
    },
    points_click_email() {
      if (this.points_click_email < 0) {
        this.points_click_email = 0;
        this.$grToast.toast("Limite de pontos mínimos atingidos!", {
          title: "Atenção",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
    },
  },
  methods: {
    debounceTags: _.debounce(function (query) {
      if (query === "") {
        this.loadingTags = false;
      } else {
        this.searchTags = query;
        this.fetchTags(this.page2, query);
      }
    }, 500),
    fetchTags(page = 1, query = "") {
      if (this.stopTags == true) {
        return;
      }
      var data = {
        id: `list?page=${page}&order_by=name&order=ASC&search=${query}`,
      };

      serviceListarTag
        .read(data)
        .then((resp) => {
          this.loadingTags = false;
          if (resp.data.length < resp.per_page && query == "") {
            this.stopTags = true;
          }
          this.tags = this.tags.concat(resp.data);
          this.tags = this.tags.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingTags = false;
        });
    },
    saveTags() {
      if (this.type_api_email === "create_email") {
        var data_tags = {
          email_tag_open: {
            tag: this.selected_tag_open,
            points: this.points_open_email,
          },
          email_tag_click: {
            tag: this.selected_tag_click,
            points: this.points_click_email,
          },
        };
        var automations = [
          {
            type: "funnel",
            trigger: "open",
            email_id: this.cardSelected.reference_id,
            args: [this.selected_tag_open.id, parseInt(this.points_open_email)],
          },
          {
            type: "funnel",
            trigger: "click",
            email_id: this.cardSelected.reference_id,
            args: [
              this.selected_tag_click.id,
              parseInt(this.points_click_email),
            ],
          },
        ];
        this.$emit("dadosQualification", data_tags, automations);
        this.$bvModal.hide("CampanhaModalQualificacao");
      }
      if (this.type_api_email === "edit_email") {
        var data_tags = {
          email_tag_open: {
            tag: this.selected_tag_open,
            points: this.points_open_email,
          },
          email_tag_click: {
            tag: this.selected_tag_click,
            points: this.points_click_email,
          },
        };
        if (this.cardSelected.send_filter.tag_dispatch) {
          data_tags.tag_dispatch = this.cardSelected.send_filter.tag_dispatch;
        }
        this.cardSelected.send_filter = data_tags;
        var data = {
          id: `/items/${this.cardSelected.id}`,
          send_filter: data_tags,
          automations: [
            {
              type: "funnel",
              trigger: "open",
              email_id: this.cardSelected.reference_id,
              args: [
                this.selected_tag_open.id,
                parseInt(this.points_open_email),
              ],
            },
            {
              type: "funnel",
              trigger: "click",
              email_id: this.cardSelected.reference_id,
              args: [
                this.selected_tag_click.id,
                parseInt(this.points_click_email),
              ],
            },
          ],
        };
        serviceFunnel
          .update(data)
          .then((resp) => {
            this.$emit("dadosQualification", data_tags);
            this.$bvModal.hide("CampanhaModalQualificacao");
          })
          .catch((error) => {
            // console.log(error);
          })
          .finally(() => {});
      }
      if (this.type_api_email === "create_email_button") {
        var data_tags = {
          email_tag_open: {
            tag: this.selected_tag_open,
            points: this.points_open_email,
          },
          email_tag_click: {
            tag: this.selected_tag_click,
            points: this.points_click_email,
          },
        };
        var automations = [
          {
            type: "funnel",
            trigger: "open",
            email_id: this.cardSelected.reference_id,
            args: [this.selected_tag_open.id, parseInt(this.points_open_email)],
          },
          {
            type: "funnel",
            trigger: "click",
            email_id: this.cardSelected.reference_id,
            args: [
              this.selected_tag_click.id,
              parseInt(this.points_click_email),
            ],
          },
        ];
        this.$emit("dadosQualification", data_tags, automations);
        this.$bvModal.hide("CampanhaModalQualificacao");
      }
    },
    clean() {
      this.points_click_email = 0;
      this.points_open_email = 0;
      this.selected_tag_open = "";
      this.selected_tag_click = "";
    },
    getTagsCard() {
      this.clean();    
      if (this.type_api_email === "create_email" ||(this.cardSelected.send_filter && this.cardSelected.send_filter.length === 0)) {
        if(this.qualification){
          this.selected_tag_open = this.qualification.email_tag_open.tag;
          this.points_open_email = this.qualification.email_tag_open.points;
          //   // Click Email
          this.selected_tag_click = this.qualification.email_tag_click.tag;
          this.points_click_email = this.qualification.email_tag_click.points;
        }
        return;
      }
      if (
        this.type_api_email === "create_email_button" ||
        (this.cardSelected.send_filter &&
          this.cardSelected.send_filter.length === 0)
      ) {
        return;
      }
      this.selected_tag_open = this.cardSelected.send_filter.email_tag_open.tag;
      this.points_open_email = this.cardSelected.send_filter.email_tag_open.points;
      // Click Email
      this.selected_tag_click = this.cardSelected.send_filter.email_tag_click.tag;
      this.points_click_email = this.cardSelected.send_filter.email_tag_click.points;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss2/main.scss";
label {
  font-weight: 500 !important;
}
.content-text-tags2 {
  p {
    font-size: 13px;
    color: #9a9a9a;
  }
  span {
    font-size: 13px;
    color: #3483fa;
  }
}

.content-text-tags {
  p {
    font-size: 13px;
    color: #9a9a9a;
  }
  span {
    font-size: 13px;
    color: #00a650;
  }
}

.content-tags {
  position: relative;
}

.content-qualification {
  display: grid;
  grid-template-columns: 1fr 195px;
  gap: 10px;
}
.container-qualification {
  display: grid;
  gap: 20px;
}
</style>

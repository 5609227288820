<template>
  <b-modal
    size="xl"
    id="ModalEmailTemplates"
    centered
    ref="ModalEmailTemplates"
    hide-footer
    @show="getTemplates"
  >
    <div class="d-block pt-4 pl-4 pr-4">
      <div class="escamento-min-card-table">
        <div style="margin-left: 10px; padding-bottom: 10px; margin-top: -20px">
          <p class="title-wizard">Listagem de templates</p>
        </div>
        <div style="padding: 0px 15px 20px 15px">
          <div class="template-content">
            <div class="rowlist">
              <div class="emails-list">
                <div
                  v-for="item in AlltemplatesNew"
                  :key="item.id"
                  :class="{ BroadcastSelected: item.id === template_id }"
                  class="Table-body"
                  @click.prevent="addTemplateThis(item.id)"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
                      stroke="#000000"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M22 6L12 13L2 6"
                      stroke="#000000"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div style="position: absolute; left: 35px">
                    <b-col style="white-space: nowrap; color: var(--gray02)">
                      {{
                        item.title.length > 20
                          ? item.title.substr(0, 20) + "..."
                          : item.title
                          ? item.title
                          : "Sem nome"
                      }}</b-col
                    >
                    <b-col>
                      <span class="data-list">
                        {{ item.send_at | date }}
                      </span>
                    </b-col>
                  </div>
                </div>
              </div>
              <div class="flex cabecalho-templates"></div>
            </div>
            <!-- <div class="tableFixHead">
                    <b-table
                      :sticky-header="stickyHeader"
                      show-empty
                      small
                      :tbody-tr-class="rowClass"
                      stacked="md"
                      :items="AlltemplatesNew"
                      :fields="fieldsTemplates"
                    >
                      <template #empty>
                        <p class="title text-center-empty">{{ emptyText }}</p>
                      </template>
                      <template #emptyfiltered>
                        <p class="title text-center-empty">{{ emptyText }}</p>
                      </template>
                      <template #cell(id)="row">
                        <div class="title-table">{{ row.item.id }}</div>
                      </template>
                      <template #cell(name)="row">
                        <div class="title-table">{{ row.item.name }}</div>
                      </template>
                      <template #cell(from_id)="row">
                        <button
                          class="btn-next-template"
                          @click.prevent="addTemplateThis(row.item.id)"
                        >
                          Selecione
                        </button>
                      </template>
                    </b-table>
                    <div class="flex cabecalho-templates">
                      <button
                        class="btn-template-modal prevz"
                        @click="lastPage()"
                      >
                        Anterior
                      </button>
                      <button
                        class="btn-template-modal nextz"
                        @click="nextPage()"
                      >
                        Proximo
                      </button>
                    </div>
                  </div> -->
            <div style="width: 80%">
              <div v-if="verPreview == false">
                <div class="card-email-null">
                  <div class="alert-card">
                    <div style="display: grid">
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          margin-bottom: 30px;
                        "
                      >
                        <svg
                          data-v-0a0f90f8=""
                          xmlns="http://www.w3.org/2000/svg"
                          width="96"
                          height="96"
                          viewBox="0 0 24 24"
                          fill="#0000"
                          stroke="#666"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-alert-circle"
                          style="align-self: center; margin: 0px auto"
                        >
                          <circle
                            data-v-0a0f90f8=""
                            cx="12"
                            cy="12"
                            r="10"
                          ></circle>
                          <line
                            data-v-0a0f90f8=""
                            x1="12"
                            y1="8"
                            x2="12"
                            y2="12"
                          ></line>
                          <line
                            data-v-0a0f90f8=""
                            x1="12"
                            y1="16"
                            x2="12.01"
                            y2="16"
                          ></line>
                        </svg>
                      </div>
                      <p
                        class="text-label"
                        style="
                          color: var(--gray03);
                          margin-bottom: 0px !important;
                        "
                      >
                        Selecione um Template
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <iframe
                v-if="verPreview"
                class="card-email"
                :srcdoc="html_modal_template"
              >
              </iframe>
            </div>
          </div>
          <div style="margin-top: 25px;display: flex;justify-content: flex-end;">
              <BaseButton
                v-if="verPreview"
                variant="link-info"
                @click="exitTemplate"
                class="mr-1"
                >Cancelar</BaseButton
              >
              <button
                v-if="verPreview"
                class="btn-next-template"
                @click="confirmaTemplate"
              >
                Confirmar
              </button>
            </div>
          <div class="flex cabecalho-templates btn-confirm" style="gap: 20px">
            <!-- <div style="padding-bottom:20px">
                <button class="btn-template-modal prevz" @click="lastPage()">
                Anterior
                </button>
                <button class="btn-template-modal nextz" @click="nextPage()">
                Proximo
                </button>
              </div> -->

            <div>
              <Paginate
                v-if="AlltemplatesNew.length > 0"
                :totalPages="totalPage"
                :activePage="pagination.currentPage"
                :disabled="true"
                @to-page="toPage"
                @per-page="perPage"
              />
            </div>

           
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Paginate from "../Paginate.vue";
//
import EmailService from "@/services/resources/EmailService";
const serviceEmail = EmailService.build();
export default {
  components: {
    Paginate,
  },
  data() {
    return {
      verPreview: false,
      templatessalvas: false,
      templatessalvas2: true,
      title: "",
      html_modal_template: "",
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
      template_id: 0,
      AlltemplatesNew: [],
      loading: false,
      template_selected: "",
      html: "",
      raw: "",
    };
  },
  methods: {
    exitTemplate() {
      this.template_id = 0;
      this.$refs["ModalEmailTemplates"].hide();
      this.template_selected = "";
    },
    confirmaTemplate() {
      this.template_id = 0;
      this.$refs["ModalEmailTemplates"].hide();
      this.$emit("saveTemplate", { html: this.html, raw: this.raw });
    },
    addTemplateThis(id) {
      this.template_id = id;
      // console.log("id real", id);
      // console.log("id selec", this.template_id);
      serviceEmail
        .read(id)
        .then((resp) => {
          let template = JSON.parse(resp.json);
          this.verPreview = true;
          this.title = resp.title;
          this.template_selected = template;
          this.html_modal_template = resp.html;
          this.templatessalvas2 = false;
          this.templatessalvas = true;
          //
          this.raw = resp.json;
          this.html = resp.html;
        })
        .catch((err) => {});
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.getTemplates(this.search);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = this.totalPage;
      this.getTemplates(this.search);
    },
    getTemplates(search = null) {
      this.loading = true;
      this.pagination.totalPages = 1;
      let data = {
        page: this.pagination.currentPage,
      };
      serviceEmail
        .search(data)
        .then((resp) => {
          this.AlltemplatesNew = resp.data;
          this.totalPage = resp.last_page;
          this.loading = false;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.btn-next-template {
  background: var(--greenn);
  border-radius: 10px;
  width: 132px;
  height: 50px;
  border: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  transition: all 0.3s;
  letter-spacing: 0.2px;

  &:hover {
    transform: scale(1.05);
  }
}
.text-label {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.2px;
  color: var(--gray01);
}
.alert-card {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: var(--white-medium);
  border-radius: 0.3rem;
  justify-content: center;
  align-items: center;
}

.card-email {
  width: 100%;
  background: #fff;
  border: 1px solid #ededf0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  height: 100%;
}

.card-email-null {
  width: 100%;
  height: 530px;
  background: #fff;
  border: 1px solid #ededf0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
}
.card-email::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}
.card-email::-webkit-scrollbar-track {
  background-color: transparent;
}
.card-email::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}
.flex.cabecalho-templates {
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: -30px;
  display: flex;
}
.emails-list {
  cursor: pointer;
  display: grid;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  grid-template-columns: 1fr 1fr;

  .Table-body {
    padding: 10px 15px !important;
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--white-medium);
    border-radius: 10px;
    transition: background-color 0.3s ease, color 0.3s ease;
    background-color: var(--white);

    svg {
      filter: invert(50%);
    }
  }
  .Table-body.BroadcastSelected {
    background-color: var(--greenn2);
  }

  .data-list {
    font-weight: 500;
    color: var(--gray03);
    font-size: 12px;
  }

  .Table-body:hover {
    border-color: var(--greenn);
    color: var(--green);
  }
}

.rowlist {
  height: 530px;
  width: 100%;
  overflow-y: auto;
}

.rowlist::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}
.rowlist::-webkit-scrollbar-track {
  background-color: transparent;
}
.rowlist::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: transparent;
}
.template-content {
  display: flex;
  gap: 30px;

  .Table-header {
    display: flex;
    justify-content: space-between;
    margin: auto;
  }
}
.title-wizard {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: var(--gray01);
  margin-top: 25px;
  white-space: nowrap;
}
.escamento-min-card-table {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 30px;
  margin-bottom: 50px;
}
</style>

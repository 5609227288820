<template>
  <div class="header-campanha">
    <div class="logo">
      <img
        @click="redirectHome"
        class="logo"
        v-if="isGreenn"
        src="@/assets/greenn_sales/SIMBOLO_.png"                
        width="35"
        style="cursor: pointer"
      />
      <img
        @click="redirectHome"
        class="logo"
        v-else
        src="@/../public/logo2.png"
        width="35"
        style="cursor: pointer"
      />
    </div>
    <div class="select">
      <!-- <multiselect
        v-model="selectedCampanha"
        :options="all_funnel"
        @input="selectedCampanhaRedirect()"
        @select="reload"
        label="title"
        class="select-camp"
        track-by="id"
        placeholder="Pesquisar"
        selectLabel=""
        deselectLabel=""
        selectedLabel=""
      >
      </multiselect> -->

      <!-- <multiselect
        style="width: 100% !important"
        class="select-camp"
        @input="selectedCampanhaRedirect()"
        @select="reload"
        v-model="selectedCampanha"
        label="title"
        track-by="id"
        placeholder="Pesquisar"
        selectLabel="Clique para selecionar"
        deselectLabel="Clique para remover"
        :options="all_funnel"
        @change="selectedCampanha = filterObj($event)"
      >
      </multiselect> -->

      <BaseSelect
        :selectModel="selectedCampanha"
        placeholder="Pesquisar"
        track-by="id"
        trackname="title"
        :array="all_funnel"
        @select="selectedCampanhaRedirect()"
        class="select-camp"
        selectLabel=""
        deselectLabel=""
        :loading="loadingCampaign"
        @search="debounceCampaign($event)"
        @callback="fetchFunnel($event)"
        @change="selectedCampanha = $event"
        :watch="true"
      >
      </BaseSelect>
    </div>
    <div class="fluxo-livre">
      <div class="switch-fluxo">
        <p class="titulo">Mover os cards</p>
        <b-form-checkbox v-model="moveDragCard" name="is_main" size="lg" switch>
        </b-form-checkbox>
      </div>
    </div>
    <div></div>
    <div class="itens-header">
      <BaseButton
        variant="outline-primary"
        class="mr-1 tm-button"
        @click="redirectList()"
      >
        Meus funis
      </BaseButton>
      <BaseButton
        variant="outline-primary"
        class="mr-1 tm-button"
        @click="$parent.createNote()"
      >
        Adicionar uma nota
      </BaseButton>
      <div v-if="!isGreenn" class="box-notificacoes" v-show="hasUpdatedToken">
        <img src="@/assets/img/icons/notfy2.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
//
import FunnelService from "@/services/resources/FunnelService";
const serviceFunnel = FunnelService.build();
//
import _ from "lodash";
//
import { jwtDecode } from 'jwt-decode';


export default {
  components: {
    Multiselect,
  },
  props: ["current_funnel"],
  data() {
    return {
      moveDragCard: true,
      selectedCampanha: null,
      stop: false,
      loadingCampaign: false,
      pageCampaign: 1,
      all_funnel: [],
      searchCampaign: "",
    };
  },
  watch: {
    current_funnel() {
      if (this.current_funnel) {
        this.selectedCampanha = this.current_funnel;
        var metas = this.current_funnel.metas;
        if (metas) {
          if (metas.find((x) => x.meta_key === "flow")) {
            this.moveDragCard =
              metas.find((x) => x.meta_key === "flow")?.meta_value != "inactive"
                ? true
                : false;
          }
        }
      }
    },
    all_funnel() {
      var selected = this.all_funnel.find(
        (funel) => funel.id == this.$route.params.funnel_id
      );
      this.selectedCampanha = selected;
    },
    selectedCampanha() {
      setTimeout(() => {
        var metas = this.current_funnel.metas;
        if (metas) {
          if (metas.find((x) => x.meta_key === "flow")) {
            this.moveDragCard =
              metas.find((x) => x.meta_key === "flow")?.meta_value != "inactive"
                ? true
                : false;
          }
        }
        // this.$parent.clearAllLines();
      }, 100);
    },
    moveDragCard() {
      var funnel_data = {
        id: this.$route.params.funnel_id,
        metas: {
          flow: this.moveDragCard ? "active" : "inactive",
        },
      };
      serviceFunnel.update(funnel_data);
      this.$emit("moveCardDrag", this.moveDragCard);
    },
  },
  methods: {
    debounceCampaign: _.debounce(function (query) {
      this.searchCampaign = query;
      this.fetchFunnel(this.pageCampaign, query);
    }, 500),
    fetchFunnel(page = 1, query = "") {
      if (this.stop || this.loadingCampaign) {
        return;
      }

      this.loadingCampaign = true;

      var data = {
        campaign_id: this.$route.params.campaign_id,
        page: page,
        order_by: "title",
        order: "ASC",
        search: this.searchCampaign,
      };
      serviceFunnel
        .search(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page) {
            this.stop = true;
          }

          this.all_funnel = this.all_funnel.concat(resp.data);
          this.all_funnel = this.all_funnel.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch(() => {})
        .finally(() => {
          this.loadingCampaign = false;
        });
    },

    async selectedCampanhaRedirect() {
      if (
        (this.selectedCampanha &&
          this.selectedCampanha.id == this.$route.params.funnel_id) ||
        this.selectedCampanha == null
      ) {
        return;
      }
      this.$router.replace({
        name: "CampanhasEtapas",
        params: {
          campaign_id: this.$route.params.campaign_id,
          funnel_id: this.selectedCampanha.id,
        },
      });
      // this.$parent.loadAllFunction();
    },
    reload() {
      // window.location.reload();
    },
    redirectHome() {
      this.$router.push({ name: "Dashboard" });
    },
    redirectList() {
      this.$router.push({ name: "CampanhasLista" });
    },
  },
  computed: {
    isGreenn() {
      return this.$store.getters["getIsGreenn"];
    },
    hasUpdatedToken(){
      var token = Cookies.get("access_token");
      const decodedToken = jwtDecode(token);
      if(!decodedToken || (decodedToken && !decodedToken.tenant_id && ! decodedToken.user_id)){
        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.itens-header {
  display: flex;
  gap: 30px;
  align-self: center;
  align-items: center;

  .tm-button {
    width: 210px;
  }
  h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    cursor: pointer;
    color: #000000;

    margin: 0;
  }
}

.switch-fluxo {
  display: flex;
  align-self: center;
  gap: 8px;
  align-items: flex-end;
  .titulo {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    margin: 0;
    color: #000000;
    white-space: nowrap;
    margin-right: 10px;
  }
}
.header-campanha {
  height: 85px;
  background: white;
  width: 100%;
  display: grid;
  padding: 13px 28px;
  grid-template-columns: 80px 1fr 150px 1.7fr 2fr;
  gap: 20px;
  position: fixed;
  z-index: 9;
  align-items: center;
}
</style>
